import React from 'react'
import { useTranslation } from 'react-i18next'
import { BooleanParam, useQueryParam } from 'use-query-params'

import { NarrowFulfilmentMethodInputType } from '@src/../__generated__/globalTypes'
import { BackToOutletListLink } from '@src/components/BackToOutletListLink/BackToOutletListLink'
import { BottomSheetWrapper } from '@src/components/BottomSheetWrapper/BottomSheetWrapper'
import { OutletStatusTranslationText } from '@src/components/OutletStatus/OutletStatusTranslationText'
import { TextClamp } from '@src/components/Text/Clamp'
import {
  OutletFulfilmentStateType,
  useOutletFulfilment,
} from '@src/hooks/outletFulfilmentAndBasketHooks/useOutletFulfilment/useOutletFulfilment'
import { useOutletAvailabilityQuery } from '@src/hooks/sharedQueries/outletAvailabilityQuery/useOutletAvailabilityQuery'
import { useBreakpoint } from '@src/hooks/useBreakpoint'
import { useFormatCurrency } from '@src/hooks/useFormatCurrency'
import { imageJitURL } from '@src/utils/imageJitURL'

import {
  Container,
  FirstRow,
  OutletDetailsContainer,
  DetailsButton,
  TitleBar,
  OpenStatus,
  Cuisines,
  RestaurantLogo,
  MinOrderText,
  FulfilmentPill,
  InnerDetails,
  DealsRow,
  OutletInfoRow,
  CoverImage,
  CuisinesAndMinOrderContainer,
  CompactHeaderTabContainer,
  OutletInfoOuterContainer,
  OutletTitle,
  HeaderContainer,
  TitleAndPillContainer,
  TabletTitleAndPillContainer,
  InfoSVGStyled,
  CartSmallSVGStyled,
  StarSVGStyled,
} from './OutletOverview.styles'

import { OutletMenuDescription } from '../OutletDescription/OutletMenuDescription'
import { OutletDetails } from '../OutletDetails/OutletDetails'
import { HeaderTab } from '../OutletHeader/HeaderTab'
import { FulfilmentMethodTabMap } from '../OutletHeader/OutletHeader'

export const OutletOverview: React.FC<{
  compactStyle: boolean
  permanentCheckout: boolean
}> = ({ compactStyle, permanentCheckout }) => {
  const outletFulfilment = useOutletFulfilment({
    stateType: OutletFulfilmentStateType.GLOBAL,
  })

  const formatCurrency = useFormatCurrency(false)
  const [showDetails, setShowDetails] = useQueryParam(
    'showDetails',
    BooleanParam
  )
  const { t } = useTranslation('outletOverview')
  const { isMobile, isSmallerThanDesktop, isTablet, isLargeTablet } =
    useBreakpoint()

  const outletStatusTextResponse = useOutletAvailabilityQuery({
    outletId: outletFulfilment.outlet.id,
    fulfilmentMethod: outletFulfilment.data.currentFulfilment.narrowType,
  })

  const statusText = outletStatusTextResponse.data
    ? outletStatusTextResponse.data.outlet?.statusText
    : outletStatusTextResponse.previousData?.outlet?.statusText

  const outlet = outletFulfilment.outlet
  const { deliveryMinimumOrderValue, collectionMinimumOrderValue } = outlet

  const selectedNarrowFulfilment =
    outletFulfilment.data.currentFulfilment.narrowType

  const fulfilmentTabs = FulfilmentMethodTabMap[selectedNarrowFulfilment]

  const getMinimumOrderValue = () => {
    if (
      selectedNarrowFulfilment === NarrowFulfilmentMethodInputType.DELIVERY &&
      deliveryMinimumOrderValue
    ) {
      return (
        <>
          {formatCurrency(deliveryMinimumOrderValue)}{' '}
          {t('min_order', { val: null })}
        </>
      )
    }
    if (
      selectedNarrowFulfilment === NarrowFulfilmentMethodInputType.COLLECTION &&
      collectionMinimumOrderValue
    ) {
      return (
        <>
          {formatCurrency(collectionMinimumOrderValue)}{' '}
          {t('min_order', { val: null })}
        </>
      )
    }
    return t('no_min_order')
  }

  const MappedDeal: Record<
    NarrowFulfilmentMethodInputType,
    string | null | undefined
  > = {
    [NarrowFulfilmentMethodInputType.COLLECTION]:
      outlet.specialOfferFlags?.collectionDeal,
    [NarrowFulfilmentMethodInputType.DELIVERY]:
      outlet.specialOfferFlags?.deliveryDeal,
    [NarrowFulfilmentMethodInputType.TABLE]:
      outlet.specialOfferFlags?.tableDeal,
  }

  const isLongTitle = () => {
    if (compactStyle && isLargeTablet) {
      return outlet.displayName.length > 12
    }
    if (isMobile) {
      return outlet.displayName.length > 27
    }
    return outlet.displayName.length > 30
  }

  return (
    <>
      {showDetails && (
        <BottomSheetWrapper
          open={showDetails}
          onClose={() => setShowDetails(undefined)}
          headerColor={'white'}
        >
          <OutletDetails />
        </BottomSheetWrapper>
      )}
      <Container compactStyle={compactStyle} isLongTitle={isLongTitle()}>
        <FirstRow compactStyle={compactStyle}>
          <OutletDetailsContainer compactStyle={compactStyle}>
            <InnerDetails>
              <HeaderContainer
                compactStyle={compactStyle}
                isLongTitle={isLongTitle()}
              >
                <TabletTitleAndPillContainer compactStyle={compactStyle}>
                  <BackToOutletListLink
                    showContainer={false}
                    showText={false}
                  />
                  {isTablet && statusText && (
                    <FulfilmentPill status={statusText.orderButtonTranslation}>
                      <OpenStatus status={statusText.orderButtonTranslation}>
                        <OutletStatusTranslationText statusText={statusText} />
                      </OpenStatus>
                    </FulfilmentPill>
                  )}
                </TabletTitleAndPillContainer>
                <TitleAndPillContainer
                  onClick={() => compactStyle && setShowDetails(true)}
                  compactStyle={compactStyle}
                  isLongTitle={isLongTitle()}
                >
                  <TitleBar
                    isLongTitle={isLongTitle()}
                    compactStyle={compactStyle}
                  >
                    <OutletTitle
                      isLongTitle={isLongTitle()}
                      compactStyle={compactStyle}
                    >
                      <TextClamp clamp={2}>{outlet.displayName}</TextClamp>
                    </OutletTitle>
                  </TitleBar>
                  {!isTablet && statusText && (
                    <FulfilmentPill status={statusText.orderButtonTranslation}>
                      <OpenStatus status={statusText.orderButtonTranslation}>
                        <OutletStatusTranslationText statusText={statusText} />
                      </OpenStatus>
                    </FulfilmentPill>
                  )}
                </TitleAndPillContainer>
                {compactStyle && !isSmallerThanDesktop && (
                  <CompactHeaderTabContainer>
                    <HeaderTab
                      fulfilmentTabs={fulfilmentTabs}
                      permanentCheckout={permanentCheckout}
                    />
                  </CompactHeaderTabContainer>
                )}
              </HeaderContainer>
              {!compactStyle && (
                <OutletInfoOuterContainer>
                  {MappedDeal[selectedNarrowFulfilment] && (
                    <DealsRow
                      compactStyle={compactStyle}
                      isLongTitle={isLongTitle()}
                    >
                      <StarSVGStyled />
                      {MappedDeal[selectedNarrowFulfilment]}
                    </DealsRow>
                  )}
                  <OutletInfoRow>
                    <CuisinesAndMinOrderContainer>
                      {outlet.outletCuisines.length > 0 && (
                        <Cuisines compactStyle={compactStyle}>
                          {outlet.outletCuisines
                            .slice(0, 3)
                            .map(cuisine => cuisine.name)
                            .join(' • ')}
                        </Cuisines>
                      )}
                      <MinOrderText compactStyle={compactStyle}>
                        <CartSmallSVGStyled id="info" title="Info" />
                        {getMinimumOrderValue()}
                      </MinOrderText>
                    </CuisinesAndMinOrderContainer>
                    {!isMobile && (
                      <DetailsButton
                        compactStyle={compactStyle}
                        onClick={() => setShowDetails(true)}
                      >
                        <InfoSVGStyled id="info" title="Info" />
                        {t('more_info')}
                      </DetailsButton>
                    )}
                  </OutletInfoRow>
                  {outlet.description && (
                    <OutletMenuDescription
                      compactStyle={compactStyle}
                      description={outlet.description}
                    />
                  )}
                  {isMobile && (
                    <DetailsButton
                      compactStyle={compactStyle}
                      onClick={() => setShowDetails(true)}
                    >
                      <InfoSVGStyled id="info" title="Info" />
                      {t('more_info')}
                    </DetailsButton>
                  )}
                </OutletInfoOuterContainer>
              )}
            </InnerDetails>
          </OutletDetailsContainer>
          {isSmallerThanDesktop ? (
            <RestaurantLogo
              compactStyle={compactStyle}
              role="img"
              aria-label={t('logo_label', {
                restaurant: outlet.displayName,
              })}
              imageUrl={imageJitURL(
                outlet.outletLogoOverride || outlet.restaurant.image,
                {
                  resize: {
                    width: 256,
                    height: 256,
                    fit: 'cover',
                  },
                  toFormat: 'jpeg',
                }
              )}
            />
          ) : (
            <CoverImage
              compactStyle={compactStyle}
              role="img"
              aria-label={t('logo_label', {
                restaurant: outlet.displayName,
              })}
              imageUrl={imageJitURL(outlet.coverImage, {
                resize: {
                  width: 168,
                  height: 168,
                  fit: 'cover',
                },
                toFormat: 'jpeg',
              })}
            >
              <RestaurantLogo
                compactStyle={compactStyle}
                permanentCheckout={permanentCheckout}
                role="img"
                aria-label={t('logo_label', {
                  restaurant: outlet.displayName,
                })}
                imageUrl={imageJitURL(
                  outlet.outletLogoOverride || outlet.restaurant.image,
                  {
                    resize: {
                      width: 168,
                      height: 168,
                      fit: 'cover',
                    },
                    toFormat: 'jpeg',
                  }
                )}
              />
            </CoverImage>
          )}
        </FirstRow>
      </Container>
    </>
  )
}
